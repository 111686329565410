
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: "ButtonLanguage",
  methods: {
    ...mapActions(['toggleLanguageDropdown'])
  }
})
