
import { defineComponent, h } from "vue";
import VueSelect from "vue-select";

export default defineComponent({
  name: "FilterRegion",
  components: {
    VueSelect,
  },
  data() {
    return {
      dropdownClearInputButton: h("span", { class: "icon icon-close" }),
      dropdownToggleButton: h("span", { class: "selection__arrow" }),
    };
  },
  computed: {
    regions() {
      return this.$store.getters.filterOptions.regions
    },
    region() {
      return this.$store.getters.filters.region;
    }
  },
  methods: {
    setRegion(value: string) {
      if (value === 'Все') {
        this.$store.dispatch('updateFilter', { key: 'region', value: null });
      } else {
        this.$store.dispatch('updateFilter', { key: 'region', value });
      }
    }
  }
});
