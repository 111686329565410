import ITendersClient from "@/interfaces/api/tendersClient";
import { ITender, ITendersResponse } from "@/interfaces/tender";
import { ICategory, IFilterOptions, IFilters } from "@/interfaces/search";

class TendersClient implements ITendersClient {

  async getNewTenders(limit: number, offset: number, filters?: IFilters): Promise<ITendersResponse> {
    const params = new URLSearchParams({
      limit: String(limit),
      offset: String(offset),
    });

    if (filters) {
      (Object.keys(filters) as Array<keyof IFilters>).forEach((key) => {
        const value = filters[key];
        if (value !== null && value !== undefined) {
          if (value instanceof Date) {
            params.append(key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`), value.toISOString().split('T')[0]);
          } else {
            params.append(key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`), String(value));
          }
        }
      });
    }

    const token = localStorage.getItem('token')

    try {
      const response = await fetch(`/api/tenders/?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data as ITendersResponse
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return {
        tenders: {
          data: [] as ITender[],
          max_page: 0,
          total: 0,
        },
        template_id: null,
      } as ITendersResponse
    }
  }
  async getTender(tenderId: string, numLots: number): Promise<ITender | null> {
    const params = new URLSearchParams({
      num_lots: String(numLots),
    });
    const token = localStorage.getItem('token')
    try {
      const response = await fetch(`/api/tenders/${tenderId}/?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data as ITender
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null
    }
  }
  async getFilterOptions(): Promise<IFilterOptions | null> {
    try {
      const response = await fetch('/api/filters/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data as IFilterOptions
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null
    }
  }
  async getCategories(lang: string): Promise<ICategory[] | null> {
    try {
      const response = await fetch(`/api/catigories/?lang=${lang}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null
    }
  }
  async searchTender(query: string, limit: number, offset: number, include_ngrams: boolean, filters?: IFilters): Promise<ITendersResponse> {
    const params = new URLSearchParams({
      query: query,
      limit: String(limit),
      offset: String(offset),
      include_ngrams: String(include_ngrams),
    });

    if(filters) {
      (Object.keys(filters) as Array<keyof IFilters>).forEach((key) => {
        const value = filters[key];
        if(value !== null && value !== undefined) {
          if (value instanceof Date) {
            params.append(key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`), value.toISOString().split('T')[0]);
          } else {
            params.append(key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`), String(value));
          }
        }
      });
    }

    const token = localStorage.getItem('token')

    try {
      const response = await fetch(`/api/tenders/?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data as ITendersResponse
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return {
        tenders: {
          data: [] as ITender[],
          max_page: 0,
          total: 0,
        },
        template_id: null,
      } as ITendersResponse
    }
  }
}

const tendersClient = new TendersClient()

export { tendersClient };
