import { Language } from "@/helpers/LanguageHelper";
import IAuthClient, { IUserInfo, IAuthResponse } from "@/interfaces/api/authClient";

class AuthClient implements IAuthClient {
  async getTokenTelegram(
    telegramId: number, 
    language: Language, 
    telegramUsername: string | null, 
    amplitudeDeviceId: string | undefined,
    amplitudeSessionId: number | undefined,
  ): Promise<IAuthResponse | null> {
    try {
      const requestBody = {
        telegram_id: telegramId,
        language: language,
        telegram_username: telegramUsername,
        amplitude_device_id: amplitudeDeviceId,
        amplitude_session_id: amplitudeSessionId,
      };

      const response = await fetch('/api/token/telegram/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return {
        telegram_id: data.telegram_id,
        token: data.access_token
      }
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation: ', error);
      return null
    }
  }
  async getUserInfo(): Promise<IUserInfo | null> {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('No token')
    }

    try {
      const response = await fetch('/api/user_info/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data as IUserInfo
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null
    }
  }
}

const authClient = new AuthClient()

export { authClient };
