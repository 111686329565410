
import { defineComponent } from 'vue';
import DatePicker from "vue-datepicker-next";

function normalizeDate(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
}

export default defineComponent({
  name: "FilterStartDate",
  components: {
    DatePicker
  },
  computed: {
    endDateFrom() {
      if (this.$store.getters.filters.endDateFrom) {
        return normalizeDate(this.$store.getters.filters.endDateFrom)
      }
      return this.$store.getters.filters.endDateFrom;
    },
    endDateTo() {
      if (this.$store.getters.filters.endDateTo) {
        return normalizeDate(this.$store.getters.filters.endDateTo)
      }
      return this.$store.getters.filters.endDateTo;
    },
    startDateFrom() {
      if (this.$store.getters.filters.startDateFrom) {
        return normalizeDate(this.$store.getters.filters.startDateFrom)
      }
      return this.$store.getters.filters.startDateFrom;
    },
    startDateTo() {
      if (this.$store.getters.filters.startDateTo) {
        return normalizeDate(this.$store.getters.filters.startDateTo)
      }
      return this.$store.getters.filters.startDateTo;
    },
    minStartDateTo() {
      if (this.$store.getters.filters.startDateFrom) {
        return normalizeDate(this.$store.getters.filters.startDateFrom)
      }
      return this.$store.getters.filters.startDateFrom;
    },
    maxStartDateFrom() {
      if (this.$store.getters.filters.startDateTo) {
        return normalizeDate(this.$store.getters.filters.startDateTo)
      }
      return this.$store.getters.filters.startDateTo;
    },
    disableStartDateFrom() {
      return (date: Date) => {
        const normalizedInputDate = normalizeDate(date)
        const afterMaxStart = normalizedInputDate > this.maxStartDateFrom;
        let beforeEndDates = false;

        if (this.endDateFrom && this.endDateTo) {
          const latestEndDate = this.endDateFrom > this.endDateTo ? this.endDateFrom : this.endDateTo;
          beforeEndDates = normalizedInputDate > latestEndDate;
        } else if (this.endDateFrom) {
          beforeEndDates = normalizedInputDate > this.endDateFrom;
        } else if (this.endDateTo) {
          beforeEndDates = normalizedInputDate > this.endDateTo;
        }

        return afterMaxStart || beforeEndDates;
      };
    },
    disableStartDateTo() {
      return (date: Date) => {
        const normalizedInputDate = normalizeDate(date);
        const beforeMinStart = normalizedInputDate < this.minStartDateTo;
        let beforeEndDates = false;

        if (this.endDateFrom && this.endDateTo) {
          const latestEndDate = this.endDateFrom > this.endDateTo ? this.endDateFrom : this.endDateTo;
          beforeEndDates = normalizedInputDate > latestEndDate;
        } else if (this.endDateFrom) {
          beforeEndDates = normalizedInputDate > this.endDateFrom;
        } else if (this.endDateTo) {
          beforeEndDates = normalizedInputDate > this.endDateTo;
        }

        return beforeMinStart || beforeEndDates;
      };
    },
  },
  methods: {
    handleDateChange(prop: 'startDateFrom' | 'startDateTo', newDate: Date) {
      this.$store.dispatch('updateFilter', { key: prop, value: newDate });
    },
  },
});
