
import { AuthFlow, validatePassword } from "@/helpers/AuthFlowHelper";
import ITelegramUser from "@/interfaces/telegram";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { telegramLoginTemp } from "vue3-telegram-login";
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper";
import LoadingSpinnerSmall from "@/components/LoadingSpinnerSmall.vue"

export default defineComponent({
  name: 'AuthModal',
  components: {
    telegramLoginTemp,
    LoadingSpinnerSmall,
  },
  data() {
    return {
      widgetIsLoading: true,
      AuthFlow,
      authStep: AuthFlow.authStart,
      botName: process.env.VUE_APP_BOT_NAME,
      email: "",
      password: "",
      passwordConfirm: "",
    }
  },
  emits: ["close"],
  methods: {
    ...mapActions(['toggleAuthModal']),
    closeModal() {
      document.body.classList.remove("is-popup-opened");
      document.body.classList.add("is-popup-closed");
      setTimeout(() => {
        this.toggleAuthModal();
        document.body.classList.remove("is-popup-closed");
        this.authStep = AuthFlow.authStart
        this.widgetIsLoading = true; // Reset loading state
        this.setupWidgetObserver(); // Set up the observer again
      }, 250);
    },
    async authTelegram(user: ITelegramUser) {
      trackEvent(EventType.login_popup_telegram)
      await this.$store.dispatch('loginUserTelegram', user)
      this.closeModal()
      if (this.intendedRoute) {
        if (this.intendedRoute.startsWith('http')) {
          window.open(this.intendedRoute, '_blank');
        } else {
          this.$router.push(this.intendedRoute)
        }
        this.$store.dispatch('setIntendedRoute', null)
      } else {
        this.$router.push({ name: 'tenderSerp', query: { page: '1' } });
      }
    },
    setupWidgetObserver() {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.target instanceof HTMLElement && mutation.target.id === `telegram-login-${this.botName}`) {
            if (mutation.attributeName === 'style') {
              this.widgetIsLoading = false;
              observer.disconnect();
            }
          }
        });
      });
      observer.observe(document.body, { attributes: true, subtree: true, attributeFilter: ['style'] });
    },
    beginEmailAuth() {
      this.authStep = AuthFlow.inputEmail
    },
    submitEmail() {
      this.authStep = AuthFlow.inputPassword
    },
    async submitPassword() {
      this.authStep = AuthFlow.authFinish
      await this.$store.dispatch('loginEmail', {email: this.email, password: this.password})
      setTimeout(() => {
        this.closeModal();
        if (this.$store.getters.intendedRoute) {
          this.$router.push(this.$store.getters.intendedRoute);
          this.$store.commit('setIntendedRoute', null);
        }       
      }, 1000);
    },
    // forgotPassword() {
    //   this.title = "Восстановление доступа"
    //   this.authStep = AuthFlow.forgotPassword
    // }
  },
  computed: {
    ...mapGetters(['showAuthModal', 'intendedRoute']),
    emailIsValid(): boolean {
      return this.email.includes("@") && this.email.includes(".");
    },
    passwordsAreValid(): boolean {
      return (
        this.password === this.passwordConfirm && validatePassword(this.password)
      );
    },
    title(): string {
      return this.$t('auth_modal.title')
    }
  },
  mounted() {
    this.setupWidgetObserver();
  },
});
