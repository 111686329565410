
import { defineComponent } from 'vue';
import DatePicker from "vue-datepicker-next";

function normalizeDate(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
}

export default defineComponent({
  name: "FilterEndDate",
  components: {
    DatePicker
  },
  computed: {
    endDateFrom() {
      if (this.$store.getters.filters.endDateFrom) {
        return normalizeDate(this.$store.getters.filters.endDateFrom)
      }
      return this.$store.getters.filters.endDateFrom;
    },
    endDateTo() {
      if (this.$store.getters.filters.endDateTo) {
        return normalizeDate(this.$store.getters.filters.endDateTo)
      }
      return this.$store.getters.filters.endDateTo;
    },
    startDateFrom() {
      if (this.$store.getters.filters.startDateFrom) {
        return normalizeDate(this.$store.getters.filters.startDateFrom)
      }
      return this.$store.getters.filters.startDateFrom;
    },
    startDateTo() {
      if (this.$store.getters.filters.startDateTo) {
        return normalizeDate(this.$store.getters.filters.startDateTo)
      }
      return this.$store.getters.filters.startDateTo;
    },
    minEndDateTo() {
      // The minimum end date 'To' should be the end date 'From', if it exists.
      if (this.$store.getters.filters.endDateFrom) {
        return normalizeDate(this.$store.getters.filters.endDateFrom)
      }
      return this.$store.getters.filters.endDateFrom;
    },
    maxEndDateFrom() {
      // The maximum end date 'From' should be the end date 'To', if it exists.
      if (this.$store.getters.filters.endDateTo) {
        return normalizeDate(this.$store.getters.filters.endDateTo)
      }
      return this.$store.getters.filters.endDateTo;
    },
    minEndDate() {
      // The minimum end date should be the earliest start date, if it exists.
      const startDateFrom = this.$store.getters.filters.startDateFrom;
      const startDateTo = this.$store.getters.filters.startDateTo;
      return startDateFrom ? normalizeDate(startDateFrom) : startDateTo ? normalizeDate(startDateTo) : null;
    },
    disableEndDateFrom() {
      return (date: Date) => {
        const normalizedInputDate = normalizeDate(date);
        return (this.minEndDate && normalizedInputDate < this.minEndDate) || 
                (this.maxEndDateFrom && normalizedInputDate > this.maxEndDateFrom);
      };
    },
    disableEndDateTo() {
      return (date: Date) => {
        const normalizedInputDate = normalizeDate(date);
        return (this.minEndDate && normalizedInputDate < this.minEndDate) || 
                (this.minEndDateTo && normalizedInputDate < this.minEndDateTo);
      };
    },
  },
  methods: {
    handleDateChange(prop: 'endDateFrom' | 'endDateTo', newDate: Date) {
      this.$store.dispatch('updateFilter', { key: prop, value: newDate });
    },
  },
});
