
import { defineComponent } from "vue";

export default defineComponent({
  name: "FilterCompany",
  computed: {
    company: {
      get() {
        return this.$store.getters.filters.company;
      },
      set(value: string) {
        if (value !== '') {
          this.$store.dispatch('updateFilter', { key: 'company', value });
        } else {
          this.$store.dispatch('updateFilter', { key: 'company', value: null });
        }
      }
    }
  },
});
