
import FooterMobile from "@/components/FooterMobile.vue";
import SearchBar from "@/components/SearchBar.vue";
import TenderCard from "@/components/TenderCard.vue";
import TheFooter from "@/components/TheFooter.vue";
import HeaderMain from "@/components/headers/HeaderMain.vue"; // @ is an alias to /src
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "HomeView",
  components: {
    HeaderMain,
    SearchBar,
    TenderCard,
    TheFooter,
    FooterMobile,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getTenders'])
  },
  methods: {
    ...mapActions(['getNewTenders', 'toggleAuthModal']),
    handleHeroBtn() {
      trackEvent(EventType.main_try_free)
    }
  },
  async created() {
    const bodyElementClasses = document.body.classList;
    bodyElementClasses.remove(...bodyElementClasses);
    bodyElementClasses.add("homepage");
    await this.getNewTenders({})
    trackEvent(EventType.main_opened)
  },
  head: {
    title: "Tender.asia – Ўзбекистон бўйлаб харидлар ва тендерларни бепул қидириш",
    meta: [
      { name: "description", content: "Бепул харидларни изланг ва Telegram орқали хабарлар олинг. Тендер майдончалари: xarid.uzex.uz, etender.uzex.uz, xt-xarid.uz, tender.mc.uz, beeline.uz" }
    ]
  }
});
