import IFavouritesClient from "@/interfaces/api/favouritesClient";
import { IPaginatedTenders, ITender } from "@/interfaces/tender";
import { IFilters } from "@/interfaces/search";

class FavouritesClient implements IFavouritesClient {
  async getFavourites(limit: number, offset: number, query?: string, filters?: IFilters | undefined): Promise<IPaginatedTenders> {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('No token')
    }

    const params = new URLSearchParams({
      limit: String(limit),
      offset: String(offset),
      ...(query ? { query: query } : {})
    });

    if(filters) {
      (Object.keys(filters) as Array<keyof IFilters>).forEach((key) => {
        const value = filters[key];
        if(value !== null && value !== undefined) {
          if (value instanceof Date) {
            params.append(key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`), value.toISOString().split('T')[0]);
          } else {
            params.append(key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`), String(value));
          }
        }
      });
    }

    try {
      const response = await fetch(`/api/favourites/?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data as IPaginatedTenders
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return {
        data: [] as ITender[],
        max_page: 0,
        total: 0,
      }
    }
  }
  async addFavourite(tenderId: string): Promise<number> {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('No token')
    }

    const requestBody = {
      tender_id: tenderId
    }

    try {
      const response = await fetch(`/api/favourites/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data.fav_id
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return 0
    }
  }
  async deleteFavourite(favId: number): Promise<boolean> {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('No token')
    }
    
    try {
      const response = await fetch(`/api/favourites/${favId}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data.success
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false
    }
  }
}

const favouritesClient = new FavouritesClient();

export { favouritesClient };
