
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { Language } from "@/helpers/LanguageHelper";
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper";

export default defineComponent({
  name: "LanguageDropdown",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      Language,
    };
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  methods: {
    ...mapActions(['toggleLanguageDropdown']),
    chooseLanguage(language: Language) {
      trackEvent(EventType.header_language, undefined, { lang: language })
      if (this.activeLanguage !== language) {
        this.$store.dispatch("selectLanguage", {
          language: language,
        });
      }
      this.$i18n.locale = this.activeLanguage;
      this.toggleLanguageDropdown();
    },
  },
});
