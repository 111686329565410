
import { defineComponent, h } from "vue";
import VueSelect from "vue-select";

export default defineComponent({
  name: "FilterSource",
  components: {
    VueSelect,
  },
  data() {
    return {
      dropdownClearInputButton: h("span", { class: "icon icon-close" }),
      dropdownToggleButton: h("span", { class: "selection__arrow" }),
    };
  },
  computed: {
    sources() {
      return this.$store.getters.filterOptions.sources
    },
    source() {
      return this.$store.getters.filters.source;
    }
  },
  methods: {
    setSource(value: string) {
      this.$store.dispatch('updateFilter', { key: 'source', value });
    }
  }
});
