import { INavigationState, IRootState } from "@/interfaces/state";
import { Module, ActionContext } from "vuex";


const navigationModule: Module<INavigationState, IRootState> = {
  state(): INavigationState {
    return {
      redirected: false,
      intendedRoute: null,
    }
  },
  getters: {
    redirected(state: INavigationState) {
      return state.redirected
    },
    intendedRoute(state: INavigationState) {
      return state.intendedRoute
    }
  },
  mutations: {
    setRedirected(state: INavigationState, payload: boolean) {
      state.redirected = payload
    },
    setIntendedRoute(state: INavigationState, payload: string | null) {
      state.intendedRoute = payload
    }
  },
  actions: {
    setIntendedRoute(context: ActionContext<INavigationState, INavigationState>, payload: string | null) {
      context.commit('setIntendedRoute', payload)
    }
  }
}

export default navigationModule
