
import { defineComponent } from 'vue';

export default defineComponent({
  name: "FilterPrice",
  computed: {
    priceFrom: {
      get() {
        if (this.$store.getters.filters.priceFrom !== undefined) {
          const priceFromString = this.$store.getters.filters.priceFrom.toString()
          const priceFromFormatted = priceFromString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          return priceFromFormatted
        }
        return this.$store.getters.filters.priceFrom
      },
      set(value: string) {
        if (value === "") {
          this.$store.dispatch('updateFilter', { key: 'priceFrom', value: null });
        } else {
          const priceFromInput = value.toString().replace(/\D/g, "")
          this.$store.dispatch('updateFilter', { key: 'priceFrom', value: +priceFromInput });
        }
      },
    },
    priceFromUnformatted() {
      return this.$store.getters.filters.priceFrom
    },
    priceTo: {
      get() {
        if (this.$store.getters.filters.priceTo !== undefined) {
          let priceToString = this.$store.getters.filters.priceTo.toString() + ""
          const priceToFormatted = priceToString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          return priceToFormatted
        }
        return this.$store.getters.filters.priceTo
      },
      set(value: string) {
        if (value === "") {
          this.$store.dispatch('updateFilter', { key: 'priceTo', value: null });
        } else {
          const priceToInput = value.toString().replace(/\D/g, "")
          this.$store.dispatch('updateFilter', { key: 'priceTo', value: +priceToInput });
        }
      },
    },
    priceToUnformatted() {
      return this.$store.getters.filters.priceTo
    },
    priceIsValid() {
      if (this.$store.getters.filters.priceFrom && this.$store.getters.filters.priceTo) {
        return this.$store.getters.filters.priceTo >= this.$store.getters.filters.priceFrom;
      }
      return true;
    },
  },
  methods: {
    clearPrice(prop: 'priceFrom' | 'priceTo') {
      this.$store.dispatch('updateFilter', { key: prop, value: null });
    },
  },
});
