import { createStore } from 'vuex'
import authModule from './auth'
import modalModule from './modals'
import tendersModule from './tenders'
import filtersModule from './filters'
import templatesModule from './templates'
import favouritesModule from './favourites'
import navigationModule from './navigation'
import companyModule from './company'

export default createStore({
  modules: {
    authModule,
    modalModule,
    tendersModule,
    filtersModule,
    templatesModule,
    favouritesModule,
    navigationModule,
    companyModule,
  }
})
