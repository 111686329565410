
import { ITender } from "@/interfaces/tender";
import { defineComponent, PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper";
import * as amplitude from '@amplitude/analytics-browser';

export default defineComponent({
  name: "TenderCard",
  props: {
    tender: {
      type: Object as PropType<ITender>,
      required: true,
    },
  },
  emits: ["remove-favourite", "add-favourite"],
  data() {
    return {
      sourceTranslator: {
        mc: "tender.mc.uz",
        xarid_uzex: "xarid.uzex.uz",
        etender: "etender.uzex.uz",
        xt_xarid: "xt-xarid.uz",
        beeline: "beeline.uz",
      } as { [key: string]: string },
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    isFavourite() {
      return !!this.tender.fav_id
    },
  },
  methods: {
    ...mapActions(['addFavourite', 'deleteFavourite', 'openNotification', 'toggleAuthModal', 'setIntendedRoute']),
    formatName(name: string) {
      const split_name = name.split(',')
      if (split_name.length > 5) {
        return split_name.slice(0, 5).join(', ') + '...';
      }
      return name
    },
    formatDate(endDate: string) {
      const date = new Date(endDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      switch (this.$t("tender_details.at")) {
        case "в":
          return `${day}.${month}.${year} в ${hours}:${minutes}`;
        case "at":
          return `${day}.${month}.${year} at ${hours}:${minutes}`;
        case "да":
          return `${day}.${month}.${year} ${hours}:${minutes} да`;
      }
    },
    async toggleFavourite() {
      if (this.isFavourite) {
        const result = await this.deleteFavourite({ 
          tenderId: this.tender.id, 
          favId: this.tender.fav_id, 
          notificationMessage: this.$t('notifications.delete_favourite')
        })

        if (result) {
          const identifyEvent = new amplitude.Identify();
          identifyEvent.add('favourites', -1);
          amplitude.identify(identifyEvent);
          trackEvent(EventType.purchase_card_delete, { purchase_id: this.tender.id })
        }
      } else {
        const result = await this.addFavourite({ 
          tenderId: this.tender.id, 
          notificationMessage: this.$t('notifications.add_favourite') 
        })

        if (result) {
          const identifyEvent = new amplitude.Identify();
          identifyEvent.add('favourites', 1);
          amplitude.identify(identifyEvent);
          trackEvent(EventType.purchase_card_save, { logged_in: this.isAuthenticated, purchase_id: this.tender.id })
        }
      }
    },
    handleClickTender(event: MouseEvent) {
      trackEvent(EventType.purchase_card_open, { logged_in: this.isAuthenticated, purchase_id: this.tender.id })
      if (!this.isAuthenticated) {
        event.preventDefault(); // prevent navigation
        this.setIntendedRoute(`/procedures/${this.tender.id}`)
        this.toggleAuthModal();
        return;
      }
      if (window.innerWidth > 768) {
        // Open the link in a new tab for desktop users
        const url = '/procedures/' + this.tender.id;
        window.open(url, '_blank');
        event.preventDefault();
      }
    },
    handleClickSource(event: MouseEvent) {
      trackEvent(EventType.purchase_card_source, { logged_in: this.isAuthenticated, purchase_id: this.tender.id })
      if (!this.isAuthenticated) {
        event.preventDefault();
        this.setIntendedRoute(this.tender.url)
        this.toggleAuthModal();
        return
      }
    },
    async handleClickFavourite() {
      if (!this.isAuthenticated) {
        trackEvent(EventType.purchase_card_save, { logged_in: this.isAuthenticated, purchase_id: this.tender.id })
        this.toggleAuthModal();
        return
      } else {
        await this.toggleFavourite();
        return
      }
    },
    handleClickCompany(event: MouseEvent) {
      const url = `/company/${this.tender.company_inn}?page=1`;
      if (window.innerWidth > 768) {
        window.open(url, '_blank');
        event.preventDefault();
      } else {
        this.$router.push({ path: url, query: {page: 1} });
        event.preventDefault();
      }
    }
  }
});
