
import { defineComponent, h }from 'vue';
import VueSelect from "vue-select";
import { mapGetters } from 'vuex';
import { ICategory } from "@/interfaces/search";

export default defineComponent({
  name: "FilterCategory",
  components: {
    VueSelect
  },
  data() {
    return {
      dropdownClearInputButton: h("span", { class: "icon icon-close" }),
      dropdownToggleButton: h("span", { class: "selection__arrow" }),
    };
  },
  computed: {
    ...mapGetters(['getLanguage']),
    categories(): string[] {
      const categories: ICategory[] = this.$store.getters.filterOptions.categories;

      return categories.map((category: ICategory) => {
        return `${category.code} ${category.title}`
      })
    },
    category() {
      return this.$store.getters.categoryOption;
    }
  },
  methods: {
    setCategory(value: string) {
      this.$store.dispatch('updateCategoryOption', value)
      this.$store.dispatch('updateFilter', { 
        key: 'category', 
        value: value === null ? value : value.slice(0,2) 
      });
    }
  }
})

