
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "PopupNotification",
  computed: {
    ...mapGetters([
      "showNotification",
      "notificationMessage",
      "notificationIsClosing",
    ]),
  },
  methods: {
    ...mapActions(["closeNotification"]),
  },
});
