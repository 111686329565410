
import { EventType, trackEvent } from '@/helpers/AmplitudeHelper';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "SearchBar",
  data() {
    return {
      searchQuery: "",
    }
  },
  methods: {
    searchTender() {
      if (['tenderSerp', 'sourceSerp', 'typeSerp', 'regionSerp', 'categorySerp'].includes(this.$route.name as string)) {
        this.$router.push({
          name: this.$route.name as string,
          query: {
            page: 1,
            ...(this.searchQuery !== "" ? { q: this.searchQuery } : {})
          }
        })
      } else {
        this.$router.push({
          name: "tenderSerp",
          query: {
            page: 1,
            ...(this.searchQuery !== "" ? { q: this.searchQuery } : {})
          }
        })
      }
      trackEvent(EventType.main_search, this.searchQuery !== '' ? { request_text: this.searchQuery } : undefined)
    }
  }
})
