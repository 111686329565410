import { authClient } from "@/api/authClient"
import { Language } from "@/helpers/LanguageHelper"
import { IAuthState, IRootState } from "@/interfaces/state"
import ITelegramUser from "@/interfaces/telegram"
import { ActionContext, Module } from "vuex"
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper"
import * as amplitude from '@amplitude/analytics-browser';

const authModule: Module<IAuthState, IRootState> = {
  state(): IAuthState {
    return {
      token: null,
      language: Language.ru,
      userId: null,
      telegramUsername: null,
    }
  },
  getters: {
    getToken(state: IAuthState) {
      return state.token
    },
    isAuthenticated(state: IAuthState) {
      return !!state.token
    },
    getLanguage(state: IAuthState) {
      return state.language
    },
    getUserId(state: IAuthState) {
      return state.userId
    },
    getTelegramUsername(state: IAuthState) {
      return state.telegramUsername
    },
  },
  mutations: {
    setToken(state: IAuthState, payload: { token: string | null}) {
      state.token = payload.token
    },
    setLanguage(state: IAuthState, payload: { language: Language }) {
      state.language = payload.language
    },
    setUserId(state: IAuthState, payload: { userId: number }) {
      state.userId = payload.userId
    },
    setTelegramUsername(state: IAuthState, payload: { telegramUsername: string }) {
      state.telegramUsername = payload.telegramUsername
    },
  },
  actions: {
    async loginUserTelegram(context: ActionContext<IAuthState, IAuthState>, payload: ITelegramUser) {
      const language = context.getters.getLanguage
      const deviceId = amplitude.getDeviceId()
      const sessionId = amplitude.getSessionId()

      const response = await authClient.getTokenTelegram(
        payload.id, 
        language, 
        payload.username, 
        deviceId,
        sessionId,
      )
      if (response) {
        localStorage.setItem('token', response.token)
        localStorage.setItem('telegram_id', response.telegram_id.toString())
        context.commit('setToken', { 
          token: response.token,
        })
        amplitude.setUserId(response.telegram_id.toString())
      }
    },
    logoutUser(context: ActionContext<IAuthState, IAuthState>) {
      context.commit('setToken', {
        token: null
      })
      context.commit('setUserId', {
        userId: null
      })
      localStorage.removeItem('token')
      trackEvent(EventType.profile_sign_out)
      amplitude.setUserId(undefined)
    },
    autoLogin(context: ActionContext<IAuthState, IAuthState>) {
      const token = localStorage.getItem('token')
      if (token) {
        context.commit('setToken', {
          token: token
        })
      }
    },
    selectLanguage(context: ActionContext<IAuthState, IAuthState>, payload: { language: Language }) {
      localStorage.setItem('language', payload.language)
      context.commit('setLanguage', {
        language: payload.language
      })
    },
    autoLanguage(context: ActionContext<IAuthState, IAuthState>) {
      const language = localStorage.getItem('language')
      if (language) {
        context.commit('setLanguage', {
          language: language
        })
      } else {
        localStorage.setItem('language', Language.uz)
        context.commit('setLanguage', {
          language: Language.uz
        })
      }
    },
    async getProfileInfo(context: ActionContext<IAuthState, IAuthState>) {
      const profileInfo = await authClient.getUserInfo()
      if (profileInfo) {
        context.commit('setUserId', { userId: profileInfo.user_id })
        if (profileInfo.telegram_username) {
          context.commit('setTelegramUsername', { telegramUsername: profileInfo.telegram_username })
        }
      }
    },
  }
}

export default authModule
