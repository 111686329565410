import { createI18n } from "vue-i18n";
import ru from './ru.json';
import en from './en.json';
import uz from './uz.json';


export default createI18n({
  locale: localStorage.getItem('language') || 'uz',
  messages: {
    ru: ru.ru,
    en: en.en,
    uz: uz.uz,
  }
})
