
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper";

export default defineComponent({
  name: "ButtonLogin",
  methods: {
    ...mapActions(['toggleAuthModal']),
    handleClick() {
      trackEvent(EventType.header_login)
      this.toggleAuthModal()
    }
  }
});
