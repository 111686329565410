
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "FooterMobile",
  computed: {
    ...mapGetters(["isAuthenticated"]),
    currentRoute () {
      return this.$route.path
    }
  },
  methods: {
    ...mapActions(["toggleAuthModal"]),
  },
});
