import { templatesClient } from "@/api/templatesClient";
import { IFilters, IPaginatedTemplates, ITemplate } from "@/interfaces/search";
import { IRootState, ITemplatesState } from "@/interfaces/state";
import { ActionContext, Module } from "vuex";
import { sourceTranslator } from "@/helpers/APIHelper";
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper";
import * as amplitude from '@amplitude/analytics-browser';
import companyClient from "@/api/companyClient";
import router from "@/router";
import i18n from "@/i18n/i18n";


type saveTemplatePayload = {
  query?: string;
  filters?: IFilters;
  notificationMessage: string;
}

const TEMPLATES_LIMIT = 20

const templatesModule: Module<ITemplatesState, IRootState> = {
  state(): ITemplatesState {
    return {
      templates: {
        data: [] as ITemplate[],
        max_page: 0,
        total: 0,
      },
      queryTemplates: '',
      hasSearchedTemplates: false,
      isLoadingTemplates: false,
      templateExists: null,
    }
  },
  getters: {
    templates(state: ITemplatesState) {
      return state.templates
    },
    queryTemplates(state: ITemplatesState) {
      return state.queryTemplates
    },
    hasSearchedTemplates(state: ITemplatesState) {
      return state.hasSearchedTemplates
    },
    isLoadingTemplates(state: ITemplatesState) {
      return state.isLoadingTemplates
    },
    templateExists(state: ITemplatesState) {
      return state.templateExists
    }
  },
  mutations: {
    setTemplates(state: ITemplatesState, payload: { templates: IPaginatedTemplates }) {
      state.templates = payload.templates
    },
    removeTemplate(state: ITemplatesState, payload: { templateId: number }) {
      state.templates.data = state.templates.data.filter(template => template.id !== payload.templateId);
      state.templates.total -= 1;
      const itemsPerPage = TEMPLATES_LIMIT;
      state.templates.max_page = Math.ceil(state.templates.total / itemsPerPage);
    },
    setQueryTemplates(state: ITemplatesState, payload: { query: string }) {
      state.queryTemplates = payload.query
    },
    setHasSearchedTemplates(state: ITemplatesState, value: boolean) {
      state.hasSearchedTemplates = value;
    },
    setIsLoadingTemplates(state: ITemplatesState, payload: { isLoading: boolean }) {
      state.isLoadingTemplates = payload.isLoading
    },
    setTemplateExists(state: ITemplatesState, templateExists: number | null) {
      state.templateExists = templateExists;
    }
  },
  actions: {
    async saveTemplate(context: ActionContext<ITemplatesState, ITemplatesState>, payload: saveTemplatePayload) {
      if (payload.filters?.source !== undefined) {
        const translatedSource = sourceTranslator[payload.filters.source as keyof typeof sourceTranslator];
        if (translatedSource) {
          payload.filters.source = translatedSource;
        }
      }

      if (payload.filters?.status !== undefined) {
        delete payload.filters.status
      }
      if (payload.filters?.endDateFrom !== undefined) {
        delete payload.filters.endDateFrom
      }
      if (payload.filters?.endDateTo !== undefined) {
        delete payload.filters.endDateTo
      }
      if (payload.filters?.startDateFrom !== undefined) {
        delete payload.filters.startDateFrom
      }
      if (payload.filters?.startDateTo !== undefined) {
        delete payload.filters.startDateTo
      }

      const result = await templatesClient.saveTemplate(payload.query, payload.filters)
      if (result !== 0) {
        context.dispatch('openNotification', { message: payload.notificationMessage })
        context.commit('setTemplateExists', result)

        const isLoggedIn = context.getters.isAuthenticated
        const identifyEvent = new amplitude.Identify();
        identifyEvent.add('subscriptions', 1);
        amplitude.identify(identifyEvent);
        if (router.currentRoute.value.name === 'tenderSerp') {
          trackEvent(EventType.search_page_template_save, { logged_in: isLoggedIn})
        } else if (router.currentRoute.value.name === 'company') {
          trackEvent(EventType.company_page_template_save, { logged_in: isLoggedIn })
        }
      } else {
        context.dispatch('openNotification', { message: i18n.global.t("notifications.add_template_failed") })
      }
    },
    
    async getTemplates(context: ActionContext<ITemplatesState, ITemplatesState>, payload: { page: number, query?: string }) {
      if (payload.query) {
        context.commit('setHasSearchedTemplates', true);
      } else {
        context.commit('setHasSearchedTemplates', false);
      }
      const offset = (payload.page - 1) * TEMPLATES_LIMIT

      context.commit('setIsLoadingTemplates', { isLoading: true })
      const templates = await templatesClient.getTemplates(TEMPLATES_LIMIT, offset, payload.query)
      if (templates.total > 0) {
        const hasCategoryCode = templates.data.some(template => template.category);
        let categoryDict: { [key: string]: string } = context.getters.categoryDict
        if (hasCategoryCode && Object.keys(categoryDict).length === 0) {
          await context.dispatch('buildCategoryDict')
          categoryDict = context.getters.categoryDict
        }

        for (const template of templates.data) {
          if (template.company && !isNaN(+template.company)) { 
            const company = await companyClient.getCompany(template.company)
            if (company) {
              template.company_name = company.title
            } else {
              template.company_name = template.company
            }
          } else if (template.company && isNaN(+template.company)) {
            template.company_name = template.company
          }
          if (template.category && categoryDict) {
            template.category_title = `${template.category} ${categoryDict[template.category]}`
          }
        }
      }
      context.commit('setTemplates', { templates: templates })
      context.commit('setIsLoadingTemplates', { isLoading: false })
    },

    async deleteTemplate(
      context: ActionContext<ITemplatesState, ITemplatesState>, 
      payload: { templateId: number, notificationMessage: string }
    ) {
      const result = await templatesClient.deleteTemplate(payload.templateId)
      if (result) {
        context.dispatch('openNotification', { message: payload.notificationMessage })
        context.commit('removeTemplate', { templateId: payload.templateId })
        if (context.getters.templateExists) {
          context.commit('setTemplateExists', null)
        }
        const identifyEvent = new amplitude.Identify();
        identifyEvent.add('subscriptions', -1);
        amplitude.identify(identifyEvent);
        if (router.currentRoute.value.name === 'tenderSerp') {
          trackEvent(EventType.search_page_template_delete)
        } else if (router.currentRoute.value.name === 'company') {
          trackEvent(EventType.company_page_template_delete)
        }
      }
    },

    setQueryTemplates(context, payload: { query: string }) {
      context.commit('setQueryTemplates', payload)
    },
  }
}

export default templatesModule
