import ICompanyClient, { ICompany, IPaginatedCompanies }from "@/interfaces/api/companyClient";


class CompanyClient implements ICompanyClient {
  async getCompany(tin: string): Promise<ICompany | null> {
    const token = localStorage.getItem('token')

    try {
      const response = await fetch(`/api/companies/${tin}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data as ICompany
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null
    }
  } 
  async getCompanies(limit: number, offset: number, query?: string): Promise<IPaginatedCompanies> {
    const params = new URLSearchParams({
      limit: String(limit),
      offset: String(offset),
      ...(query ? {query: query} : {})
    });

    try {
      const response = await fetch(`/api/companies/?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data as IPaginatedCompanies
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return {
        data: [] as ICompany[],
        max_page: 0,
        total: 0,
      }
    }
  }
}

const companyClient = new CompanyClient()

export default companyClient
