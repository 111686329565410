
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { EventType, trackEvent } from '@/helpers/AmplitudeHelper';

export default defineComponent({
  name: "SearchHeader",
  props: {
    isSecondary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['filters']),
    query: {
      get() {
        return this.$store.getters.query;
      },
      set(value: string) {
        this.$store.dispatch('setQuery', { query: value });
      }
    }
  },
  methods: {
    ...mapActions(['setQuery', 'applyFilters', 'searchTenders', 'getNewTenders', 'setPage', 'resetFilters']),
    async searchTender() {
      if (['tenderSerp', 'ngramSerp', 'sourceSerp', 'typeSerp', 'regionSerp', 'categorySerp'].includes(this.$route.name as string)) {
        this.applyFilters()
      } else {
        this.setPage({ page: 1 })
        this.resetFilters({ shouldApplyFilter: false })
        this.$router.push({ 
          name: 'tenderSerp', 
          query: { 
            page: 1, 
            ...(this.query ? { q: this.query } : {}) 
          }
        })
      }
      trackEvent(EventType.header_search, this.query !== '' ? { request_text: this.query } : undefined)
    }

  }
});
