enum AuthFlow {
  authStart = "authStart",
  inputEmail = "inputEmail",
  inputPassword = "inputPassword",
  forgotPassword = "forgotPassword",
  authFinish = "authFinish",
}

const validatePassword = (password: string) => {
  return (
    password.length >= 8 &&     // Password length greater than 8
    /\p{Lu}/u.test(password) && // At least one uppercase unicode characters
    /\p{Ll}/u.test(password) && // At least one lowercase unicode character
    /\d/.test(password) &&      // At least one number
    !/\s/.test(password)        // No spaces
  );
};

export { AuthFlow, validatePassword };
