import { favouritesClient } from "@/api/favouritesClient";
import { IFilters } from "@/interfaces/search";
import { IFavouritesState, IRootState } from "@/interfaces/state";
import { IPaginatedTenders, ITender } from "@/interfaces/tender";
import { ActionContext, Module } from "vuex";
import { sourceTranslator } from "@/helpers/APIHelper";


const FAVOURITES_LIMIT = 10


const favouritesModule: Module<IFavouritesState, IRootState> = {
  state(): IFavouritesState {
    return {
      favourites: {
        data: [] as ITender[],
        max_page: 0,
        total: 0,
      },
      queryFavourites: '',
      hasSearchedFavourites: false,
      isLoadingFavourites: false,
    };
  },
  getters: {
    favourites(state: IFavouritesState): IPaginatedTenders {
      return state.favourites;
    },
    queryFavourites(state: IFavouritesState) {
      return state.queryFavourites
    },
    hasSearchedFavourites(state: IFavouritesState) {
      return state.hasSearchedFavourites
    },
    isLoadingFavourites(state: IFavouritesState) {
      return state.isLoadingFavourites
    }
  },
  mutations: {
    setFavourites(
      state: IFavouritesState,
      payload: { favourites: IPaginatedTenders }
    ) {
      state.favourites = payload.favourites;
    },
    removeFavourite(state: IFavouritesState, payload: { tenderId: string }) {
      state.favourites.data = state.favourites.data.filter(
        (favourite) => favourite.id !== payload.tenderId
      );
      state.favourites.total -= 1;
      const itemsPerPage = FAVOURITES_LIMIT;
      state.favourites.max_page = Math.ceil(state.favourites.total / itemsPerPage);
    },
    setQueryFavourites(state: IFavouritesState, payload: { query: string }) {
      state.queryFavourites = payload.query
    },
    setHasSearchedFavourites(state: IFavouritesState, value: boolean) {
      state.hasSearchedFavourites = value
    },
    setIsLoadingFavourites(state: IFavouritesState, payload: { isLoading: boolean }) {
      state.isLoadingFavourites = payload.isLoading
    }
  },
  actions: {
    async addFavourite(
      context: ActionContext<IFavouritesState, IFavouritesState>,
      payload: { tenderId: string, notificationMessage: string }
    ) {
      const result = await favouritesClient.addFavourite(payload.tenderId);
      if (result) {
        context.commit("setTenderFavouriteStatus", { tenderId: payload.tenderId, fav_id: result }, { root: true })
        context.dispatch("openNotification", { message: payload.notificationMessage })
        return true
      } else {
        return false
      }
    },

    async getFavourites(
      context: ActionContext<IFavouritesState, IFavouritesState>,
      payload: { page: number, query?: string, filters?: IFilters }
    ) {
      if (payload.query) {
        context.commit('setHasSearchedFavourites', true)
      } else {
        context.commit('setHasSearchedFavourites', false)
      }
      const offset = (payload.page - 1) * FAVOURITES_LIMIT;

      if (payload.filters?.source !== undefined) {
        const translatedSource = sourceTranslator[payload.filters.source as keyof typeof sourceTranslator];
        if (translatedSource) {
          payload.filters.source = translatedSource;
        }
      }
      
      context.commit("setIsLoadingFavourites", { isLoading: true })
      const favourites = await favouritesClient.getFavourites(FAVOURITES_LIMIT, offset, payload.query, payload.filters);
      context.commit("setFavourites", { favourites: favourites });
      context.commit("setIsLoadingFavourites", { isLoading: false })
    },

    async deleteFavourite(
      context: ActionContext<IFavouritesState, IFavouritesState>,
      payload: { tenderId: string, favId: number, notificationMessage: string }
    ) {
      const result = await favouritesClient.deleteFavourite(payload.favId);
      if (result) {
        if (context.getters.favourites.data.length > 0) {
          context.commit("removeFavourite", { tenderId: payload.tenderId });
        }
        context.commit("setTenderFavouriteStatus", { tenderId: payload.tenderId, fav_id: null }, { root: true })
        context.dispatch("openNotification", { message: payload.notificationMessage})
        return true
      } else {
        return false
      }
    },

    setQueryFavourites(context, payload: { query: string }) {
      context.commit("setQueryFavourites", payload)
    }
  },
};

export default favouritesModule;
