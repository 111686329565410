
import { defineComponent } from "vue";

export default defineComponent({
  name: "FilterType",
  computed: {
    types() {
      return [
        this.$t("search.type_all"),
        this.$t("search.type_auction"),
        this.$t("search.type_selection"),
        this.$t("search.type_tender"),
        this.$t("search.type_civil_contract"),
        this.$t("search.type_competition"),
        this.$t("search.type_framework")
      ]
    },
    type: {
      get() {
        return this.$store.getters.filters.type;
      },
      set(value: string | null) {
        if (value === this.$t("search.type_all")) {
          this.$store.dispatch('updateFilter', { key: 'type', value: null });
        } else {
          let typeFilter = ''
          switch (value) {
            case this.$t("search.type_auction"):
              typeFilter = 'Аукцион'
              break
            case this.$t("search.type_selection"):
              typeFilter = 'Отбор'
              break
            case this.$t("search.type_tender"):
              typeFilter = 'Тендер'
              break
            case this.$t("search.type_civil_contract"):
              typeFilter = 'Гражданско-правовой договор'
              break
            case this.$t("search.type_competition"):
              typeFilter = 'Конкурс'
              break
            case this.$t("search.type_framework"):
              typeFilter = 'Рамочное соглашение'
              break
          }
          this.$store.dispatch('updateFilter', { key: 'type', value: typeFilter });
        }
      },
    },
    typeTranslator() {
      return {
        'Аукцион': this.$t("search.type_auction"),
        'Отбор': this.$t("search.type_selection"),
        'Тендер': this.$t("search.type_tender"),
        'Гражданско-правовой договор': this.$t("search.type_civil_contract"),
        'Конкурс': this.$t("search.type_competition"),
        'Рамочное соглашение': this.$t("search.type_framework"),
      }
    },
    checked() {
      return (typeOption: string) => {
        return this.type ? this.typeTranslator[this.type as keyof typeof this.typeTranslator] === typeOption : typeOption === this.$t('search.type_all');
      }
    }
  },
  methods: {
    setType(event: Event) {
      const target = event.target as HTMLInputElement;
      this.type = target.value;
    },
  },
});
