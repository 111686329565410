
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import LanguageDropdown from "../modals/LanguageDropdown.vue";
import ButtonLanguage from "./ButtonLanguage.vue";
import ButtonLogin from "./ButtonLogin.vue";
import SearchHeader from "./SearchHeader.vue";
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper";

export default defineComponent({
  name: "TheHeader",
  components: {
    SearchHeader,
    ButtonLanguage,
    LanguageDropdown,
    ButtonLogin,
  },
  methods: {
    ...mapActions(['toggleAuthModal', 'toggleLanguageDropdown']),
    trackHeaderFavourites() {
      trackEvent(EventType.header_favorites)
    },
    trackHeaderTemplates() {
      trackEvent(EventType.header_templates)
    },
    trackHeaderProfile() {
      trackEvent(EventType.header_profile)
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'showAuthModal', 'showLanguageDropdown'])
  },
});
