
import { defineComponent } from "vue";
import FilterCompany from "../filters/FilterCompany.vue";
import FilterEndDate from "../filters/FilterEndDate.vue";
import FilterPrice from "../filters/FilterPrice.vue";
import FilterRegion from "../filters/FilterRegion.vue";
import FilterSource from "../filters/FilterSource.vue";
import FilterStartDate from "../filters/FilterStartDate.vue";
import FilterType from "../filters/FilterType.vue";
import FilterCategory from "../filters/FilterCategory.vue";
import { mapActions, mapGetters } from "vuex";
import { trackEvent, EventType } from "@/helpers/AmplitudeHelper";

export default defineComponent({
  name: "MobileFiltersModal",
  components: {
    FilterRegion,
    FilterType,
    FilterCategory,
    FilterPrice,
    FilterStartDate,
    FilterEndDate,
    FilterCompany,
    FilterSource,
  },
  props: {
    query: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters(['showMobileFiltersModal', 'filters', 'filterUpdated', 'isLoading']),
    resetIsDisabled() {
      let disabledFilter = (Object.keys(this.filters).length === 0 && !this.filterUpdated)
      switch (this.$route.name) {
        case 'company':
          disabledFilter = (Object.keys(this.filters).length === 1 && this.filters.company && !this.filterUpdated)
          break
        case 'sourceSerp':
          disabledFilter = (Object.keys(this.filters).length === 1 && this.filters.source && !this.filterUpdated)
          break
        case 'typeSerp':
          disabledFilter = (Object.keys(this.filters).length === 1 && this.filters.type && !this.filterUpdated)
          break
        case 'regionSerp':
          disabledFilter = (Object.keys(this.filters).length === 1 && this.filters.region && !this.filterUpdated)
          break
        case 'categorySerp':
          disabledFilter = (Object.keys(this.filters).length === 1 && this.filters.category && !this.filterUpdated)
      }
      return disabledFilter || this.isLoading
    },
    applyIsDisabled() {
      return (Object.keys(this.filters).length === 0 && !this.filterUpdated) || this.isLoading
    }
  },
  methods: {
    ...mapActions(['resetFilters', 'applyFilters', 'toggleMobileFiltersModal']),
    reset() {
      let resetFiltersPayload = {}
      switch (this.$route.name) {
        case 'company':
          resetFiltersPayload = { keepFilter: { filterType: 'company' }}
          break
        case 'sourceSerp':
          resetFiltersPayload = { keepFilter: { filterType: 'source' }}
          break
        case 'typeSerp':
          resetFiltersPayload = { keepFilter: { filterType: 'type' }}
          break
        case 'regionSerp':
          resetFiltersPayload = { keepFilter: { filterType: 'region' }}
          break
        case 'categorySerp':
          resetFiltersPayload = { keepFilter: { filterType: 'category' }}
          break
      }
      this.$store.dispatch('resetFilters', resetFiltersPayload)
      trackEvent(EventType.filters_reset)
      this.toggleMobileFiltersModal()
    },
    apply() {
      this.applyFilters()
      this.toggleMobileFiltersModal()
    }
  },
});
